<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            id="input-line-code"
            ref="refLineCode"
            v-model="selectedLineCode"
            :items="lineInfoList"
            label="라인명"
            item-text="라인명"
            item-value="라인코드"
            placeholder="라인선택"
            auto-select-first
            @change="onLineChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            id="input-machine-code"
            ref="refLineCode"
            v-model="selectedMachineCode"
            :items="machineInfoList"
            label="설비명"
            item-text="설비명"
            item-value="설비코드"
            placeholder="설비선택"
            auto-select-first
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-dialog
            ref="refStartDateDialog"
            v-model="startDateModal"
            class="date-picker"
            :return-value.sync="startDate"
            :width="datePickerWidth"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                label="고장발생일"
                autocomplete="off"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-if="startDateModal"
              v-model="startDate"
              locale="ko-ko"
              @input="$refs.refStartDateDialog.save(startDate)"
            />
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog
            ref="refStartTime"
            v-model="startTimeModal"
            :close-on-content-click="false"
            :return-value.sync="startTime"
            :width="datePickerWidth"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startTime"
                label="고장발생시간"
                autocomplete="off"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-time-picker
              v-if="startTimeModal"
              v-model="startTime"
              format="24hr"
              full-width
              @click:minute="$refs.refStartTime.save(startTime)"
            />
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="remark"
            label="고장내용"
            autocomplete="off"
            placeholder="고장내용 요약"
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            width="100%"
            height="42px"
            color="primary"
            @click="onClickSave"
          >
            설비고장 신고
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import axios from 'axios'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
// import HttpService from '../../share/service/HttpService'
// import AesCrypto from '../../share/service/AesCrypto'
import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'

export default {
  name: 'EmptView',
  components: {
    Loading
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      selectedLineCode: null,
      lineInfoList: [],
      selectedMachineCode: null,
      machineInfoList: [],
      startDateModal: false,
      startDate: null,
      startTimeModal: false,
      startTime: null,
      datePickerWidth: '350px',
      remark: null,
      connected: false,
      stompClient: null,
      socket: null
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.datePickerWidth = this.getDatePickerWidth()
    this.startDate = AppLib.getToday()
    this.startTime = AppLib.getNowTime()
    this.refreshLineInfo()
  },
  beforeDestroy () {
    this.disconnect()
  },
  updated () {},
  methods: {
    getDatePickerWidth () {
      return `${AppLib.getDatePickerWidth(this.$store)}px`
    },
    initData () {
      this.selectedLineCode = null
      this.selectedMachineCode = null
      this.remark = null
    },
    onClickSave () {
      if (this.selectedLineCode === null) {
        this.$snotify.info('생산라인을 선택하여 주세요.')
        return
      }
      if (this.selectedMachineCode === null) {
        this.$snotify.info('고장설비를 선택하여 주세요.')
        return
      }
      if (this.remark === null) {
        this.$snotify.info('고장내용을 간략히 입력하여 주세요.')
        return
      }
      this.$snotify.confirm(
        '설비고장 신고 하시겠습니까? (신고후 취소는 할 수 없습니다.)',
        '',
        {
          timeout: 10000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          buttons: [
            {
              text: '신고',
              action: toast => {
                this.$snotify.remove(toast.id)
                const param = {
                  접수일자: AppLib.getNow(),
                  라인코드: this.selectedLineCode,
                  설비코드: this.selectedMachineCode,
                  고장시간: `${this.startDate} ${this.startTime}`,
                  고장내역: this.remark,
                  createid: this.userInfo.userName,
                  updateid: this.userInfo.userName
                }
                this.$_sp
                  .runInsertSqlProc('설비고장', null, [param])
                  .then(data => {
                    this.$snotify.info('등록 되었습니다.')
                    this.initData()
                    this.connect(param)
                  })
                  .catch(error => {
                    this.isLoading = false
                    if (error.response.status === ConstDef.GENERRAL_ERROR) {
                      this.$snotify.error(error.response.data.message)
                    } else {
                      this.$snotify.error(
                        `에러코드 : ${error.response.status}`
                      )
                    }
                  })
              }
            },
            {
              text: '취소',
              action: toast => {
                this.$snotify.remove(toast.id)
              }
            }
          ]
        }
      )
    },
    onLineChange () {
      this.$_sp
        .runNoEncodeFindProc('spFindAllMachineByLineCode', {
          라인코드: this.selectedLineCode
        })
        .then(data => {
          this.machineInfoList = this.$_sp.MakeModel(data)
          if (this.machineInfoList.length === 1) {
            this.selectedMachineCode = this.machineInfoList[0].설비코드
          }
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshLineInfo () {
      const line = this.$_sp.runNoEncodeFindProc('spFindAllLineByDivision', {
        부서코드: this.userInfo.division
      })
      const noline = this.$_sp.runNoEncodeFindProc(
        'spFindAllLineByNotDivision',
        { 부서코드: this.userInfo.division }
      )
      axios
        .all([line, noline])
        .then(
          axios.spread((...reponse) => {
            const line = this.$_sp.MakeModel(reponse[0])
            const line2 = this.$_sp.MakeModel(reponse[1])
            this.lineInfoList = line.concat(line2)
          })
        )
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    // stomp ***********
    send (param) {
      console.log('Send message:' + param)
      if (this.stompClient && this.stompClient.connected) {
        this.stompClient.send(
          '/app/machineFaultReg',
          JSON.stringify(param),
          {}
        )
        this.disconnect()
      }
    },
    connect (param) {
      this.socket = new SockJS(ConstDef.stompUrl)
      // const options = { debug: true, protocols: Stomp.VERSIONS.supportedProtocols() }
      this.stompClient = Stomp.over(this.socket)
      this.stompClient.connect(
        {},
        frame => {
          this.connected = true
          console.log(frame)
          this.send(param)
          /*
          this.stompClient.subscribe('/topic/greetings', tick => {
            console.log(tick)
            this.received_messages.push(JSON.parse(tick.body).content)
          })
          */
        },
        error => {
          console.log(error)
          this.connected = false
        }
      )
    },
    disconnect () {
      if (this.stompClient) {
        this.stompClient.disconnect()
      }
      this.connected = false
    }
  }
}
</script>

<style lang="scss"></style>
